import en from "@/locales/en.json";
import fr from "@/locales/fr.json";
import it from "@/locales/it.json";
import { App, inject, Plugin, Ref, ref } from "vue";

const lang: Record<string, JSON> = { fr, en, it };

type JSON = { [x: string]: string };

interface I18NOption {
  locale: string;
  fallback: string;
}

interface I18N {
  translate: (key: string) => string;
  locale: Ref<string>;
}

const defaultOption: I18NOption = { locale: "fr", fallback: "fr" };

export default (options?: Partial<I18NOption>): Plugin => {
  const _options = { ...defaultOption, ...options };
  return {
    install(app: App) {
      let locale = ref(_options.locale);
      if (lang[locale.value] == null) {
        locale = ref(_options.fallback);
      }
      const translate = (key: string): string => {
        if (lang[locale.value][key] == null)
          console.log(`I18N not found ${key}`);
        return lang[locale.value][key] ?? key;
      };
      app.config.globalProperties.$t = translate;
      app.provide<I18N>("i18n", { translate, locale });
    },
  };
};

export const useI18n = (): I18N => {
  return inject("i18n") as I18N;
};
