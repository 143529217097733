interface Field {
  name: string;
  required: boolean;
  isArray: boolean;
  reference: Service<any>;
}

type Service<T> = new (...args: any[]) => T;

type SerializablePropertyDecorator = (
  target: Serializable<any>,
  prop: string
) => void;

export class Serialize {
  private static updateField(
    field: Partial<Field> = {}
  ): SerializablePropertyDecorator {
    return function (target: Serializable<any>, prop: string): void {
      if (target._fields == null) target._fields = new Map();
      let _field = target._fields.get(prop);
      if (_field == null) {
        target._fields.set(prop, { name: prop });
      }
      _field = target._fields.get(prop);
      target._fields.set(prop, { ..._field, ...field });
    };
  }

  static validate(field: Partial<Field> = {}): SerializablePropertyDecorator {
    return Serialize.updateField(field);
  }

  static required(required = true): SerializablePropertyDecorator {
    return Serialize.updateField({ required });
  }

  static reference(reference: Service<any>): SerializablePropertyDecorator {
    return Serialize.updateField({ reference });
  }
}

abstract class Serializable<T> {
  public _fields?: Map<string, Partial<Field>>;

  private static createService<T>(Ctor: Service<T>, ...args: any[]): T {
    return new Ctor(...args);
  }

  private readonly validator = {
    set: (target: Serializable<T>, prop: string, value: any) => {
      const field = this._fields?.get(prop);
      if (field != null) {
        if (field.reference != null) {
          if (Array.isArray(value) || field.isArray === true) {
            const refs: T[] = [];
            if (Array.isArray(value)) {
              for (const ref of value) {
                refs.push(Serializable.createService(field.reference, ref));
              }
            }
            Reflect.set(target, prop, refs);
          } else {
            const ref = Serializable.createService(field.reference, value);
            Reflect.set(target, prop, ref);
          }
        } else {
          Reflect.set(target, prop, value);
        }
      }
      return true;
    },
  };

  constructor(data?: Partial<T>) {
    const proxy: Serializable<T> = new Proxy(this, this.validator);
    Object.assign(proxy, data);
    return proxy;
  }

  private toJSON(): Partial<T> {
    const json: any = {};
    for (const [k, v] of Object.entries(this)) {
      if (this._fields?.has(k) === true) {
        json[k] = v;
      }
    }
    return json;
  }
}

export default Serializable;
