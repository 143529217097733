
import { defineComponent } from "@vue/runtime-core";
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";

export default defineComponent({
  name: "ConnectedLayout",
  components: {
    NavBar,
    SideBar,
  },
});
