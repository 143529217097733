import App from "@/App.vue";
import http from "@/plugins/http";
import session from "@/plugins/session";
import router from "@/router";
import { createApp } from "vue";
import createI18n from "./plugins/i18n";
import { UseRootState } from "./store/root";

const app = createApp(App);

const i18n = createI18n({
  fallback: "en",
  locale: navigator.language.substring(0, 2) ?? "fr",
});

app
  .provide("RootState", UseRootState())
  .use(session)
  .use(i18n)
  .use(http)
  .use(router)
  .mount("#app");
