
import Device from "@/models/device";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "DeviceOtherServices",
  props: {
    device: { type: Object, required: true },
  },
  setup(props) {
    const device = props.device as Device;

    const services = computed(() =>
      device.expose?.filter((v) => ["http", "https"].indexOf(v) < 0)
    );

    return {
      services,
    };
  },
});
