
import Device from "@/models/device";
import { UseApi } from "@/modules/api";
import { defineComponent, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "DeviceAddForm",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { error, post, errorText } =
      UseApi<unknown, Partial<Device | { code: string }>>();

    const from = route.meta.from as string;

    const device = ref({ ...new Device(), code: null });

    async function save() {
      try {
        await post("/device", device.value, "device_add");
        router.push({ name: from, params: { reload: "1" } });
      } catch (error) {
        console.log(error);
      }
    }

    function cancel() {
      router.push({ name: from });
    }

    return {
      device,
      error,
      post,
      errorText,
      save,
      cancel,
    };
  },
});
