
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "CardComponent",
  props: {
    title: { type: String, required: false },
  },
  setup: (props) => {
    return { ...props };
  },
});
