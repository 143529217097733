
import LoginForm from "@/components/LoginForm.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LoginForm,
  },
})
export default class Login extends Vue {}
