import Axios, { AxiosInstance } from "axios";
import { App, Plugin } from "vue";

const httpPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$http = Axios;
  },
};

export default httpPlugin;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $http: AxiosInstance;
  }
}
