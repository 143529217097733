import Serializable, { Serialize } from "./serializable";

export default class Device extends Serializable<Device> {
  @Serialize.validate()
  public id?: string;

  @Serialize.validate()
  public name?: string;

  @Serialize.validate()
  public online?: boolean;

  @Serialize.validate()
  public _id?: string;

  @Serialize.validate({ isArray: true })
  public expose?: Array<string>;
}
