import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_device_list = _resolveComponent("device-list")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h1", null, _toDisplayString(_ctx.$t("dashboard")), 1),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_device_list, { class: "col-1" })
      ])
    ])
  ]))
}