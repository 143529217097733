
import DeviceConnection from "@/components/DeviceConnection.vue";
import DeviceOtherServices from "@/components/DeviceOtherServices.vue";
import CardComponent from "@/components/CardComponent.vue";
import Device from "@/models/device";
import { UseApi } from "@/modules/api";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useI18n } from "@/plugins/i18n";

export default defineComponent({
  name: "DeviceList",
  components: { DeviceConnection, CardComponent, DeviceOtherServices },
  setup() {
    const { data, get, del } = UseApi<Array<Device>, unknown>();
    const route = useRoute();
    const deviceConnectionError = ref();
    const filter = ref("");

    const i18n = useI18n();

    async function getDevices() {
      try {
        await get("/devices");
      } catch (error) {
        console.log(error);
      }
    }

    watch(
      () => route.params.reload,
      async (reload) => {
        if (reload === "1") await getDevices();
      }
    );

    async function deleteDevice(id: string) {
      try {
        let response = confirm(
          i18n.translate("Do you really want to remove it ?")
        );
        if (response === true) {
          await del(`/device/${id}`);
          await get("/devices");
        }
      } catch (error) {
        console.log(error);
      }
    }

    const columns = ["name", "id", "online"];

    function exclude<T>(d: T, props: Array<keyof typeof d>): Partial<T> {
      var o: Partial<T> = {};
      let k: keyof typeof d;
      for (k in d) {
        if (props.indexOf(k) < 0) o[k] = d[k];
      }
      return o;
    }

    const devices = computed(() => {
      if (data?.value != null && Array.isArray(data.value)) {
        return data.value
          .filter((device) => device.id != null)
          .filter((device) => {
            if (filter.value != "") {
              let k: keyof typeof device;
              for (k in device) {
                if (
                  device[k]
                    ?.toString()
                    .toLocaleLowerCase()
                    .includes(filter.value.toLocaleLowerCase())
                )
                  return true;
              }
              return false;
            } else return true;
          })
          .reduce((acc: Array<Partial<Device>>, v: Partial<Device>) => {
            acc.push(exclude(v, ["_id"]));
            return acc;
          }, reactive([]))
          .sort((a, b) => a.id?.localeCompare(b.id ?? "") ?? 0);
      }
    });

    const translate = (value: unknown): unknown => {
      if (value === true) return i18n.translate("device_true");
      else if (value === false) return i18n.translate("device_false");
      else return value;
    };

    onMounted(async () => {
      await getDevices();
    });

    return {
      columns,
      devices,
      deleteDevice,
      deviceConnectionError,
      translate,
      filter,
    };
  },
});
