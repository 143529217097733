
import { UseApi } from "@/modules/api";
import { useSession } from "@/plugins/session";
import { computed, defineComponent } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Account",
  setup() {
    const { get } = UseApi();
    const router = useRouter();
    const session = useSession();

    const email = computed(() => session.getEmail());

    async function logout() {
      try {
        await get("/logout");
        session.destroy();
        router.replace("/login");
      } catch (error) {
        console.log(error);
      }
    }

    return { logout, email };
  },
});
