
import Loading from "@/components/Loading.vue";
import ConnectedLayout from "@/layouts/ConnectedLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { defineComponent } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { DefaultLayout, ConnectedLayout, Loading },
  inject: ["RootState"],
  computed: {
    layout(): string {
      const route = useRoute();
      return (route.meta.layout ?? "default-layout") as string;
    },
  },
});
