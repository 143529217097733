import { reactive } from "vue";

export interface IRootState {
  apiGlobalError?: string;
  loading: boolean;
}

const state = reactive<IRootState>({
  apiGlobalError: undefined,
  loading: false,
});

export const UseRootState = (): IRootState => {
  return state;
};
