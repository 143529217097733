import { App, Plugin } from "vue";

/**
 *
 *
 * @interface ISession
 */
interface ISession {
  create(email: string): void;
  destroy(): void;
  isAuthenticated(): boolean;
}

/**
 *
 *
 * @class Session
 * @implements {ISession}
 */
class Session implements ISession {
  private static instance: Session;

  /**
   * Creates an instance of Session.
   * @memberof Session
   */
  private constructor() {
    //
  }

  /**
   *
   *
   * @static
   * @return {*}  {Session}
   * @memberof Session
   */
  public static GetInstance(): Session {
    if (this.instance == null) this.instance = new Session();

    return this.instance;
  }

  /**
   *
   *
   * @memberof Session
   */
  public create(email: string): void {
    localStorage.setItem("user", JSON.stringify({ email }));
  }

  /**
   *
   *
   * @memberof Session
   */
  public destroy(): void {
    localStorage.removeItem("user");
  }

  /**
   *
   *
   * @return {*}  {boolean}
   * @memberof Session
   */
  public isAuthenticated(): boolean {
    return localStorage.getItem("user") != null;
  }

  public getEmail(): string | null {
    try {
      const user = JSON.parse(localStorage.getItem("user") ?? "{}");
      return user.email;
    } catch (error) {
      return null;
    }
  }
}

const sessionPlugin: Plugin = {
  install: (app: App): void => {
    app.config.globalProperties.$session = Session.GetInstance();
    app.provide("session", Session.GetInstance());
  },
};

export const useSession = (): Session => Session.GetInstance();

export default sessionPlugin;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $session: ISession;
  }
}
