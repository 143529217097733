
import { Options, Vue } from "vue-class-component";
import RegisterSuccessForm from "@/components/RegisterSuccessForm.vue";

@Options({
  components: {
    RegisterSuccessForm,
  },
})
export default class RegisterSuccess extends Vue {}
