import { UseRootState } from "@/store/root";
import axios, { AxiosError } from "axios";
import { computed, ref, watch } from "vue";
import { Router, useRouter } from "vue-router";

/**
 *
 *
 * @interface IApi
 */
// interface IApi {
//   errorText: ComputedRef<string | null>;
//   get: (url: string, namespace?: string) => Promise<void>;
//   del: (url: string, namespace?: string) => Promise<void>;
//   post: (
//     url: string,
//     payload?: Record<string, unknown>,
//     namespace?: string
//   ) => Promise<AxiosResponse<unknown>>;
//   put: (
//     url: string,
//     payload?: unknown,
//     namespace?: string
//   ) => Promise<AxiosResponse<unknown>>;
//   data: Ref<unknown>;
//   error: Ref<{ e?: AxiosError; namespace?: string }>;
// }

export const UseApi = <T, U>() => {
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || "/",
    withCredentials: true,
  });
  const data = ref<T>();
  const error = ref<{ e?: AxiosError; namespace?: string }>({});
  const rootState = UseRootState();

  const _router: Router = useRouter();

  const errorText = computed(() => {
    if (error.value.e != null)
      return `${
        error.value.namespace != null ? `${error.value.namespace}_` : ""
      }${error.value.e?.response?.status}`.toLocaleUpperCase();
    return null;
  });

  /**
   *
   *
   * @param {string} url
   * @param {string} [namespace]
   * @return {*}
   */
  function get(url: string, namespace?: string) {
    rootState.loading = true;
    rootState.apiGlobalError = undefined;
    error.value = { namespace };

    return api
      .get(url)
      .then((res) => {
        data.value = res.data;
      })
      .catch((e) => {
        error.value = { ...error.value, e };
        throw e;
      })
      .finally(() => (rootState.loading = false));
  }

  /**
   *
   *
   * @param {string} url
   * @param {string} [namespace]
   * @return {*}
   */
  function del(url: string, namespace?: string) {
    rootState.loading = true;
    rootState.apiGlobalError = undefined;
    error.value = { namespace };

    return api
      .delete(url)
      .then((res) => {
        data.value = res.data;
      })
      .catch((e) => {
        error.value = { ...error.value, e };
        throw e;
      })
      .finally(() => (rootState.loading = false));
  }

  /**
   *
   *
   * @param {string} url
   * @param {Record<string, unknown>} [payload]
   * @param {string} [namespace]
   * @return {*}
   */
  function post(url: string, payload?: U, namespace?: string) {
    rootState.loading = true;
    rootState.apiGlobalError = undefined;
    error.value = { namespace };

    return api
      .post(url, payload)
      .then((res) => (data.value = res.data))
      .catch((e: AxiosError) => {
        error.value = { ...error.value, e };
        throw e;
      })
      .finally(() => (rootState.loading = false));
  }

  /**
   *
   *
   * @param {string} url
   * @param {Record<string, unknown>} [payload]
   * @param {string} [namespace]
   * @return {*}
   */
  function put(url: string, payload?: U, namespace?: string) {
    rootState.loading = true;
    rootState.apiGlobalError = undefined;
    error.value = { namespace };

    return api
      .put(url, payload)
      .then((res) => (data.value = res.data))
      .catch((e: AxiosError) => {
        error.value = { ...error.value, e };
        throw e;
      })
      .finally(() => (rootState.loading = false));
  }

  watch([error], () => {
    if (error.value.e?.response?.status === 440) {
      rootState.apiGlobalError = "SESSION_EXPIRED";
      _router.replace("/login");
    } else if (error.value == null) rootState.apiGlobalError = undefined;
  });

  return { errorText, get, post, data, error, del, put };
};
