
import Login from "@/models/login";
import { UseApi } from "@/modules/api";
import { useSession } from "@/plugins/session";
import { UseRootState } from "@/store/root";
import { defineComponent, reactive, toRefs, watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LoginForm",
  inject: ["i18n"],
  methods: {},
  setup() {
    const router = useRouter();
    const session = useSession();
    const rootState = UseRootState();

    type LoginPostDataType = {
      email?: string;
      password?: string;
    };

    const userState = reactive<LoginPostDataType>({
      email: undefined,
      password: undefined,
    });

    const { data, error, get, post, errorText } =
      UseApi<Login, LoginPostDataType>();

    watch(data, (login) => {
      if (data.value?.email != null) {
        session.create(data.value?.email);
        router.push("/home");
      }
    });

    async function doLogin() {
      try {
        await post("/login", { ...userState }, "login");
      } catch (error) {
        console.log(error);
      }
    }

    return {
      data,
      error,
      get,
      post,
      errorText,
      doLogin,
      ...toRefs(userState),
      ...toRefs(rootState),
    };
  },
});
