
import { Options, Vue } from "vue-class-component";
import RegisterForm from "@/components/RegisterForm.vue";

@Options({
  components: {
    RegisterForm,
  },
})
export default class Register extends Vue {}
