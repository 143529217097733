
import Device from "@/models/device";
import { UseApi } from "@/modules/api";
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {
    id: { type: String, required: true },
  },
  name: "DeviceUpdateForm",
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const { data, error, get, put, errorText } = UseApi<Device, Device>();
    const state = reactive({
      data,
    });

    const from = route.meta.from as string;

    async function update() {
      try {
        await put(`/device/${props.id}`, data.value, "device");
        router.push({ name: from, params: { reload: "1" } });
      } catch (error) {
        console.log(error);
      }
    }

    function cancel() {
      router.push({ name: from });
    }

    async function getDevice(idDevice: string) {
      try {
        await get(`/device/${idDevice}`, "device");
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      getDevice(props.id);
    });

    return {
      ...toRefs(state),
      data,
      cancel,
      error,
      get,
      errorText,
      update,
    };
  },
});
