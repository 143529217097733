
import Device from "@/models/device";
import { UseApi } from "@/modules/api";
import { defineComponent, toRefs, watch } from "vue";

type TunnelResponse = { http?: string; https?: string };

export default defineComponent({
  name: "DeviceConnection",
  emits: ["error"],
  props: {
    device: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const device = props.device as Device;
    const { errorText, data, get } = UseApi<TunnelResponse, unknown>();

    const canConnect = (protocol: string): boolean =>
      device.online === true && device.expose?.includes(protocol) === true;

    async function connect(protocol: string) {
      try {
        await get(
          `/device/${device.id}/tunnel/${protocol}`,
          "device_connection"
        );
      } catch (error) {
        console.log(error);
      }
    }

    watch([data], () => {
      if (data.value?.https) window.open(data.value?.https, "_blank");
      else if (data.value?.http) window.open(data.value?.http, "_blank");
    });

    watch(errorText, (errorText) => {
      emit("error", errorText);
    });

    return {
      ...toRefs(device),
      canConnect,
      connect,
      errorText,
    };
  },
});
