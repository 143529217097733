
import { UseApi } from "@/modules/api";
import { computed, defineComponent, reactive, toRefs } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default defineComponent({
  directives: {},
  name: "RegisterForm",
  setup() {
    const router = useRouter();

    const state = reactive({
      email: {
        email: null,
        confirmation: null,
      },
      password: {
        password: null,
        confirmation: null,
      },
      code: null,
      error: "",
    });

    const { data, get, post, errorText } = UseApi();

    const postData = computed(() => {
      return {
        email: state.email.email,
        password: state.password.password,
        code: state.code,
      };
    });

    async function register() {
      state.error = "";
      if (state.email.email !== state.email.confirmation)
        return (state.error = "EMAIL_NOT_MATCH");
      if (state.password.password !== state.password.confirmation)
        return (state.error = "PASSWORD_NOT_MATCH");
      try {
        await post("/register", { ...postData.value }, "register");
        router.push("/register-success");
      } catch (error) {
        console.log(error);
      }
    }

    return {
      data,
      get,
      post,
      errorText,
      register,
      postData,
      ...toRefs(state),
    };
  },
});
