
import DeviceList from "@/components/DeviceList.vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "Home",
  components: {
    DeviceList,
  },
});
