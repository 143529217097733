import DeviceAddForm from "@/components/DeviceAddForm.vue";
import DeviceUpdateForm from "@/components/DeviceUpdateForm.vue";
import { useSession } from "@/plugins/session";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import RegisterSuccess from "@/views/RegisterSuccess.vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "default-layout",
      title: "login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      layout: "default-layout",
      title: "register",
    },
  },
  {
    path: "/register-success",
    name: "RegisterSuccess",
    component: RegisterSuccess,
    meta: {
      layout: "default-layout",
      title: "register",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      layout: "connected-layout",
      title: "home",
      authRequired: true,
    },
    children: [
      {
        path: "device/add",
        component: DeviceAddForm,
        meta: {
          from: "Home",
        },
      },
      {
        path: "device/:id",
        props: true,
        component: DeviceUpdateForm,
        meta: {
          from: "Home",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const session = useSession();
  if (to.meta.authRequired && !session.isAuthenticated()) return next("/login");
  next();
});

export default router;
